import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO
      title="About Natural Afro Hair Care"
      description="Natural Afro Hair Care about page, Everything you need to know about NAHC and our business"
    />
    <h1>About Natural Afro Hair Care</h1>
    {/* <p>
      Welcome to Article Two{" "}
      <Link to="/article-one/">go back to article one</Link>
    </p> */}
    <h2 style={{color:"Orchid"}}>Welcome to Natural Afro Hair Care or NAHC</h2>

    <p>Hi I’m Eric, the Editor for Natural Afro Hair Care. This site is a place for learning about or natural hair through quality content.</p>
    <p>I'm part of the team because of my passion for natural hair and vision to see this business grow. My story is a long one of growing to love my hair and learn more about it. I am still learning about natural hair care and I use this site as a place to document my research.</p>
    <p>I have a few responsibilities here and I'll take you through some of them.</p>

    <h3>Ideas</h3>
    <p>Coming up with general ideas for the site. This includes topics for the information & educational articles and the small web applications/tools. I also help choose the images and title for the articles as well as general formatting and design.</p>

    <h3>Collaboration</h3>
    <p>I look for other business owners and web masters to collaborate with. We are excited to work with other people to share knowledge and grow each of our businesses. We believe that things are easier and we grow stronger when we all work together. We can interview each other, share our views on various topics, review each other's products and anything else we can think of.</p>
    <p>We've done interviews with some <Link to="/talking-natural-hair-with-celai-west/" style={{ textDecoration: "none" }}>
    amazing people
    </Link> who are proud of their natural hair and who embrace it professionally and in other in their everyday life. Browse the interview section on the homepage to have a read.</p>

    <h3>Affiliates</h3>
    <p>Being affiliated with companies that sell products is what allows us to run this site. The most common is the amazon associates program. They have a very large listing on natural hair products and their platform is accessible to people all over the world. This isn't the case if you are a reader from Africa but we'll get onto that later, check the affiliates continued section below.</p>

    <h2>The future of NAHC</h2>
    <p>We would like to create all sorts of useful tools and information for the natural hair community.</p>
    <p>Here are some of the things we have discussed and are excited to share with you.</p>
    <ul>
      <li>A web application that can identify what hair type you have from an image. It will be an image classifier that uses machine learning and runs in the web so you don't need to download anything. You just upload an image and it will give you back your hair type.</li>
      <li>We are constantly working on a <Link to="/black-owned-hair-products/#list" style={{ textDecoration: "none" }}>
      list of black owned hair companies
    </Link>. It tells you who the owner is and what their website is so you can buy directly from them. I have a link to amazon if they sell on there. Lastly, I have a discount code column that will contain discount codes for online shopping so our readers can get exclusive deals. This is a huge task that involves a lot of negotiating but it's something we are excited about.</li>
      <li>Homemade recipe book that you can download as a pdf, read on kindle or get a hardback copy of. It will be full of information on the most popular natural ingredients so you can understand what nutritional benefits they have and why we use them. The book will have tried and tested recipes that help you grow beautiful hair at little cost.</li>
      <li>Speak to you guys more. We are open with the fact that we want your feedback, some of you take us up on the offer but we want to hear from much more of you.</li>
      <li>A line of apparel - specifically t-shirts to begin with. This isn't really a priority but something we have discussed. It's something that our audience really has to be invested in so we will need to hold some focus groups with the community before we go ahead.</li>
    </ul>

    <h2>How can you help us?</h2>
    <p>Firstly, if you are interested in working with us on anything including the list of projects above then get in touch via Facebook. Working with other creatives is something we look forward to.</p>
    <p><strong>Share our content</strong>. You wouldn't believe how much word of mouth actually helps. If you see something on our site and like it, someone else you know might like it too. It's easy to forget when you are browsing the web but a share is something we really appreciate. Just copy and paster the web address. Here it is for convenience...</p> 
    <p><strong style={{color: "Orchid", fontWeight:"400"}}>https://naturalafrohaircare.com/</strong></p>

    <h2>Advertising</h2>
    <p>We are open to advertising, get in touch for our rates. We have a highly targeted readership/ following who come to us for advice on natural hair and natural hair products. If you have a product or service that we believe suits our audience then we are happy to consider your proposal.</p>
    <p>One of our design philosophies is "clean". Quality content is our priority so we have space for one or two relevant ads per page. We will work with you to create a well-designed ad that we are both happy with.</p>

    <h2>Affiliates continued</h2>
    <p>Amazon is trusted in the way that you can be confident you will get the product as advertised. A lot of people are worried about being taken advantage of online and on smaller sites, this is very much a possibility. Even on Amazon but the likelihood is much lower if you buy from a reputable seller.</p>
    <p>This is why we encourage business owners to get their product on Amazon because their reach will grow so much bigger. Your profit per sale might be smaller but you should sell a whole lot more. Not to mention, you can recruit a bunch of influencers to promote your product for a commission with no up-front cost.</p>
    <p>Back to Africa (see above) - and in other words people that don't have access to Amazon or just don't want to support their business. There are other affiliate programs, none as popular as Amazon but they do exist. We would love to speak to our readers that aren't able to reach some of the products we recommend. You guys can tell us directly how we can help out.</p>
    <p>To conclude - if you have a product and want to increase your reach and chances or people promoting it then get it on a platform that allows people to promote it. Not only for commission but for greater international reach.</p>

    <h2>Our Social media accounts</h2>
    <p>We use social media to help with the reach of NAHC. Most of our readers have some form of social media and these platforms let us reach you and interact in a place that you are comfortable with.</p>
    <p>The downside of social media for us is that it can be a time drain so we try to go with quality over quantity. Right now, you won't see us posting everyday but when we do post, hopefully you like it.</p>
    <p>Each of the social media platforms have their benefits and disadvantages. Instead of setting up a profile on all of them, we chose the ones that we thought we could provide the most value and give us enough diversity to make the effort worthwhile. Here's a look at what you can expect on each one.</p>

    <h3>Facebook</h3>
    <p><a
        href="https://www.facebook.com/naturalafrohaircare/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       @naturalafrohaircare
      </a></p>
    <p>The Facebook page is our longest standing presence on the web in any form. It came before the website and anything else. Facebook gets a bad rap sometimes but it is still one of the best places to interact with and learn about a business.</p>
    <p>We do have email which is great for business interactions and sending correspondence. But, for general chat with our readers and customers, Facebook is by far the easiest and feels the most natural.</p>
    <p>The natural hair groups on Facebook are an amazing place to meet our readers, customers and like-minded creators. They are also a great place to talk directly to product owners. It is the place that inspired our list of over 100 black owned afro hair products. If you see an opinion piece from another creative/ product owner then we might just have found them in a Facebook group.</p>
    <p>So, about our own Facebook page. We use it to share updates on any new content we put out as well as any noteworthy news we think you might want to hear. If we release something that isn't part of the website, you will probably hear about it on Facebook first.</p>

    <h3>Instagram</h3>
    <p><a
        href="https://www.instagram.com/naturalafrohair/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       @naturalafrohair
      </a></p>
    <p>We use our Instagram to post curated content from around the web. Not like the news but more of a gallery of beautiful people with natural hair. The aim is to give our followers inspiration to stay on their journey and embrace their own hair.</p>
    <p>In the stories, we post about content on our site and other things we are working on. Kind of like a mini Facebook account. In the future we want to show more of a behind the scenes and the creative process that goes into the content we make. It might be a great way for you all to get to know us a bit better.</p>
    <p>We also search hashtags related to natural hair to see what people are posting about. As you know, Instagram isn't just for images. There are also some great conversations in the comments section.</p>

    <h3>Pinterest</h3>
    <p><a
        href="https://www.pinterest.co.uk/naturalafrohaircare/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       @naturalafrohaircare
      </a></p>
    <p>This is where we curate boards for inspiration on natural hair styles. In our opinion, Pinterest is the number 1 place by far to get quality images. The suggestion algorithm seems to know us far better than Instagram does and suggests things that regularly get saved to a board.</p>
    <p>As well as boards putting together boards based on content from other people, we post our own content. We use the content on our website as inspiration to make magazine style images that we think are more fitting to an audience on Pinterest. They are usually the same header image with some editing like a blurring and a white filter then a text overlay in our signature Orchid pink colour.</p>
    <p>We haven't been on Pinterest for as long as the other platform so we are still getting used to it but we know it's potential. We enabled businesses tools recently so we can see how you guys interact with our content. This will help us see what you like/ dislike to bring you better content.</p>

    <h3>Quora</h3>
    <p>Quora is a great platform for reading content and answering questions in your field of expertise. We have personal accounts and have experimented with the platform a little bit. It has great potential for reach based on what we've seen and we would love to put more time into it.</p>

    <h2>See you there</h2>
    <p>If you are on any of the platforms above, follow us and say hi. We'd love to hear from each and every one of you.</p>

   
    
    <p>Here's those social media accounts again, get in touch!</p>
    <p>Facebook -<a
        href="https://www.facebook.com/naturalafrohaircare/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       @naturalafrohaircare
      </a></p>
      <p>Instagram -<a
        href="https://www.instagram.com/naturalafrohair/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       @naturalafrohair
      </a></p>
      <p>Pinterest -<a
        href="https://www.pinterest.co.uk/naturalafrohaircare/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       @naturalafrohaircare
      </a></p>

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default About
